<template>
 <header>
     <v-container fluid>
        <v-row
          align="center"
          justify="center"
        >
          <v-col cols="10">
                    <div class="header-logo">
                        <a href="index.html"><img src="../assets/images/logo.png" alt=""></a>
                    </div>
        </v-col>
        </v-row>
      </v-container>

           
</header>
</template>
<script>
/* eslint-disable */
const TIMEOUT = 1;
export default {
  name:"top",
  data(){
      return {
          showMenuMobile:false,
      }
  },
  mounted () {
    // From testing, without a brief timeout, it won't work.
    //if (this.$route.hash) {
      //setTimeout(() => this.scrollTo(this.$route.hash), TIMEOUT)
    //}
  },
  methods:{
      mobileMenu(){
        this.showMenuMobile = !this.showMenuMobile
      },
      scrollTo(hashtag) {
          //if(hashtag)
            //setTimeout(() => { location.href = hashtag }, TIMEOUT)
    }
  }
}
</script>