<template>
    <v-app id="app">
       <v-main>
          <navbar/>
          <layout/>
          <popup/>
       </v-main>
     </v-app>
   </template>
   
<script>
/* eslint-disable */
import layout from './layout';
import navbar from './navbar';
import popup from './popup';
export default {
     name: 'Home',
     components: {
       layout,
       navbar,
       popup
     },
     data: () => ({
       primaryDrawer:{model:false},
       items: [
         {
           title: "Inicio",
           icon: "mdi-home",
           link: "home"
   
         },
         {
           title: "Propiedades",
           icon: "mdi-domain",
           link: "properties"
         },
         {
           title: "Incidencia",
           icon: "mdi-pencil-plus",
           link: "parte"
         },
         {
           title: "Contacto",
           icon: "mdi-email",
           link: "contact"
         },
       ]
     }),
   };
   </script>