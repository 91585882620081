var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"featured-properites-section section"},[_c('div',{staticClass:"container"},[_vm._m(0),_c('v-row',_vm._l((_vm.properties),function(ref,i){
var name = ref.name;
var location = ref.location;
var description = ref.description;
var price = ref.price;
var img = ref.img;
var rent = ref.rent;
var stars = ref.stars;
var link = ref.link;
var maps = ref.maps;
return _c('v-col',{key:i,attrs:{"cols":"12","md":"4","sm":"4"}},[_c('v-card',{staticClass:"mx-auto my-12",attrs:{"max-width":"374"}},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"deep-purple","height":"10","indeterminate":""}})],1),_c('div',[_c('v-img',{attrs:{"height":"250","src":img}})],1),_c('v-card-title',[_vm._v(_vm._s(name))]),_c('v-card-text',[_c('v-row',{staticClass:"mx-0",attrs:{"align":"center"}},[_c('v-rating',{attrs:{"value":stars,"color":"amber","dense":"","half-increments":"","readonly":"","size":"14"}}),_c('div',{staticClass:"grey--text ml-4"},[_vm._v(" "+_vm._s(stars)+" ")])],1),_c('div',{staticClass:"my-4 subtitle-1"},[_c('v-divider',{staticClass:"mx-4"})],1),_c('div',{domProps:{"innerHTML":_vm._s(description)}},[_vm._v(_vm._s(description))])],1),_c('v-divider',{staticClass:"mx-4"}),(price)?_c('v-card-title',[_vm._v(_vm._s(price))]):_vm._e(),_c('v-card-actions',[(link === '')?_c('v-btn',{attrs:{"color":"deep-orange lighten-2","text":""},on:{"click":function($event){return _vm.$vuetify.goTo('#contact')}}},[_vm._v(" Más Información ")]):_c('v-btn',{attrs:{"color":"deep-orange lighten-2","text":"","href":link,"target":"_blank"}},[_vm._v(" Más Información ")])],1)],2)],1)}),1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"section-title text-center col mb-30 mb-md-20 mb-xs-20 mb-sm-20"},[_c('a',{attrs:{"id":"properties"}}),_c('h2',[_vm._v("Inmuebles en Venta/Alquiler")]),_c('p',[_vm._v("Aprovecha la oportunidad y alquila o compra inmuebles en la Región de Murcia")])])])}]

export { render, staticRenderFns }