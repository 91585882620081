<template>
  
<footer class="footer-section section ">
<!--    
    <div class="footer-top footer-bg pt-70 pt-md-50 pt-sm-30 pt-xs-20 pb-100 pb-md-90 pb-sm-70 pb-xs-60">
        <div class="container">
           
        </div>
    </div> -->
    <div class="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <p>Copyright &copy; {{ new Date().getFullYear() }} CitySol S.L. <a href="https://citysol.es/">Todos los derechos reservados</a> </p>
                </div>
            </div>
        </div>
    </div>
    
</footer>
</template>
<script>
export default {
  name:"foot",
}
</script>