<template>
  <div class="featured-properites-section section">
    <div class="container">
      
        <div class="row">
            <div class="section-title text-center col mb-30 mb-md-20 mb-xs-20 mb-sm-20">
                <a id="properties"/>
                <h2>Inmuebles en Venta/Alquiler</h2>
                <p>Aprovecha la oportunidad y alquila o compra inmuebles en la Región de Murcia</p>
            </div>
        </div>

        <v-row>
            <v-col
            v-for="({ name, location, description, price, img, rent, stars, link, maps }, i) in properties"
            :key="i"
            cols="12"
            md="4"
            sm="4"
          >
                <v-card
                    class="mx-auto my-12"
                    max-width="374"
                >
                    <template slot="progress">
                    <v-progress-linear
                        color="deep-purple"
                        height="10"
                        indeterminate
                    ></v-progress-linear>
                    </template>

                    <div>
                        <v-img
                            height="250"
                            :src="img"
                        ></v-img>
                        <!-- <span v-if="rent" class="level-stryker">Alquilado</span> !-->
                    </div>

                    <v-card-title>{{name}}</v-card-title>

                    <v-card-text>
                    <v-row
                        align="center"
                        class="mx-0"
                    >
                        <v-rating
                        :value="stars"
                        color="amber"
                        dense
                        half-increments
                        readonly
                        size="14"
                        ></v-rating>

                        <div class="grey--text ml-4">
                            {{stars}}
                        </div>
                    </v-row>

                    <div class="my-4 subtitle-1">
                        <!-- <a :href="maps" _target="blank">
                            {{location}}
                        </a> !-->
                        <v-divider class="mx-4"></v-divider>
                    </div>

                    <div v-html="description">{{description}}</div>
                    </v-card-text>

                    <v-divider class="mx-4"></v-divider>

                    <v-card-title v-if="price">{{price}}</v-card-title>

                    <v-card-actions>
                        <v-btn
                            v-if="link === ''"
                            color="deep-orange lighten-2"
                            text
                            @click="$vuetify.goTo('#contact')"
                        >
                            Más Información
                        </v-btn>
                        <v-btn
                            v-else
                            color="deep-orange lighten-2"
                            text
                            :href="link"
                            target="_blank"
                        >
                            Más Información
                        </v-btn>
                        <!-- <v-btn
                            color="deep-blue lighten-2"
                            text
                            :href="maps"
                            target="_blank"
                        >
                            Ubicación
                        </v-btn> !-->
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </div>
</div>
</template>
<script>
export default {
    name:"properties",
    data() {
        return {
            properties: [
                {   
                    name: "Alquiler de Viviendas", 
                    location:"", 
                    description:"Múltiples opciones.<br>Apartamentos de 1, 2 y 3 dormitorios<br> Áticos", 
                    img: "img/3b.jpg", 
                    rent:false,
                    stars:5,
                    link:"https://www.idealista.com/pro/citysol/", 
                    maps:""
                },
                {   
                    name: "Venta de Viviendas",
                    location:"",
                    description:"Viviendas recién reformadas.<br>Pisos, Apartamentos y Áticos.", 
                    //price:"Desde 399€/mes", 
                    img: "img/cama1C.jpeg",
                    rent:false,
                    stars:5,
                    link:"https://www.idealista.com/pro/citysol/", 
                    maps:""
                },
                {   
                    name: "Venta de Viviendas de Obra Nueva",
                    location:"",
                    description:"Viviendas de obra nueva.<br>Pisos, Apartamentos y Áticos.", 
                    //price:"Desde 399€/mes", 
                    img: "img/santa-eugenia.png",
                    rent:false,
                    stars:5,
                    link:"https://www.idealista.com/pro/citysol/", 
                    maps:""
                },
                {   
                    name: "Venta de Oficinas",
                    location:"",
                    description:"Oficinas.<br>Nuevas, reformadas y amuebladas.", 
                    //price:"Desde 399€/mes", 
                    img: "img/oficina-mazarron-interior.jpg",
                    rent:false,
                    stars:5,
                    link:"https://www.idealista.com/pro/citysol/", 
                    maps:""
                },
                {   
                    name: "Alquiler de Oficinas",
                    location:"",
                    description:"Oficinas.<br>Nuevas, reformadas y amuebladas.", 
                    //price:"Desde 399€/mes", 
                    img: "img/nave-archena-interior.jpg",
                    rent:false,
                    stars:5,
                    link:"https://www.idealista.com/pro/citysol/", 
                    maps:""
                },
                {   
                    name: "Venta de Locales",
                    location:"",
                    description:"Locales.<br>En distintas ubicaciones y de distintos tipos.", 
                    //price:"Desde 399€/mes", 
                    img: "img/local-almeria-interior.jpg",
                    rent:false,
                    stars:5,
                    link:"https://www.idealista.com/pro/citysol/", 
                    maps:""
                },
                {   
                    name: "Alquiler de Locales",
                    location:"",
                    description:"Locales.<br>En distintas ubicaciones y de distintos tipos.", 
                    //price:"Desde 399€/mes", 
                    img: "img/pizzeria-espinardo.jpg",
                    rent:false,
                    stars:5,
                    link:"https://www.idealista.com/pro/citysol/", 
                    maps:""
                },
                {   
                    name: "Venta de Terrenos",
                    location:"",
                    description:"Terrenos.<br>En distintas ubicaciones y de distintos tipos.", 
                    //price:"Desde 399€/mes", 
                    img: "img/terreno.jpg",
                    rent:false,
                    stars:5,
                    link:"https://www.idealista.com/pro/citysol/", 
                    maps:""
                },
                {   
                    name: "Venta de Garajes",
                    location:"",
                    description:"Garajes.<br>En distintas ubicaciones y de distintos tipos.", 
                    //price:"Desde 399€/mes", 
                    img: "img/garaje-zig-zag-interior.jpg",
                    rent:false,
                    stars:5,
                    link:"https://www.idealista.com/pro/citysol/", 
                    maps:""
                },
                {   
                    name: "Alquiler de Garajes",
                    location:"",
                    description:"Garajes.<br>En distintas ubicaciones y de distintos tipos.", 
                    //price:"Desde 399€/mes", 
                    img: "img/garaje-calle-mayor-espinardo.jpg",
                    rent:false,
                    stars:5,
                    link:"https://www.idealista.com/pro/citysol/", 
                    maps:""
                },
                // {   
                //     name: "Cortijo de la Plata - Murcia", 
                //     location:"Carril Cosmes, 32, 30164 Cabezo de la Plata, Murcia", 
                //     description:"Amplio cortijo recién reformado y amueblado con piscina privada y patio para pasar unos días agradables con familiares y amigos. Piscina de diseño con cascada y zona estilo playa. Rodeada con césped artificial, sombras, barbacoa, baños, frigorífico, hamacas y espacio libre.", 
                //     img: "img/piscina-cortijo.jpg", 
                //     rent: false, 
                //     stars:4.8, 
                //     price: "Desde 99€/día",
                //     link:"https://airbnb.com/h/cortijo-piscina-murcia", 
                //     maps:"https://www.google.es/maps/place/Cortijo+de+la+Plata/@37.9759152,-1.0021903,17z/data=!3m1!4b1!4m5!3m4!1s0xd639df53df8f78f:0xf6ff4b783f7649e0!8m2!3d37.9759347!4d-1.0001819"
                // },
                // {   
                //     name: "Casa con vistas en La Almadraba de Cabo Gata", 
                //     location:"Calle Camino Viejo Del Faro, 5, 04150 La Almadraba de Monteleva, Almería", 
                //     description:"Apartamento en La Almadraba con vistas a las salinas de Cabo Gata.   Cuenta con: - Dos habitaciones grandes que incluyen una cama de matrimonio y una cama de matrimonio con una litera, más sofá cama. - Un baño equipado con todo lo necesario para su uso y comodidad. - Una cocina preparada con horno, microondas, cubiertos y utensilios para albergar a todos los huéspedes. - Salón con vistas al parque natural de cago gata con un amplio sofá convertible en cama. Así como con una pequeña chimenea.", 
                //     img: "img/salon-cabo-gata.jpeg", 
                //     rent: false, 
                //     stars:4.6, 
                //     price: "Desde 49€/día",
                //     link:"https://airbnb.com/h/almadraba", 
                //     maps:"https://www.google.com/maps/place/36°44'45.0%22N+2°12'45.8%22W/@36.7458371,-2.2132742,236m/data=!3m2!1e3!4b1!4m14!1m7!3m6!1s0xd7a8def55d2d0e9:0x26d36fb3443dbd40!2sLa+Almadraba+de+Monteleva,+Almer%C3%ADa!3b1!8m2!3d36.7440015!4d-2.2101403!3m5!1s0x0:0x0!7e2!8m2!3d36.7458363!4d-2.2127268"
                // }
            ]
        }
    },
    methods:{
        goto(link){
            console.log(link)

        }
    }
}
</script>