<template>
  <v-content>
    <navbar/>
      <iframe src="https://tally.so/r/mRG6OP" width="100%" height="100%" frameborder="0" marginheight="0" marginwidth="0" title="Feedback Gestión Soporte Técnico"></iframe>
    <foot/>
  </v-content>
</template>

<script>
import navbar from './navbar_incidencia';
import foot from './foot';
export default {
  name:"Incidencia",
  components: {
    navbar,
    foot
  }
}
</script>