<template>
    <div>
    <v-navigation-drawer
       v-model="primaryDrawer.model"
         :clipped="true"
         :floating="false"
         app
         overflow
       >
         <v-list-item>
           <v-list-item-content>
             <v-list-item-title class="title" :color="'white'">
                 <v-img
                   class="mx-2"
                   src="img/logo.png"
                   max-height="60"
                   max-width="60"
                   contain
                 ></v-img>
             </v-list-item-title>
           </v-list-item-content>
         </v-list-item>
         <v-divider></v-divider>
         <v-divider></v-divider>
         <v-list
           dense
           nav
         >
         <v-list-item
              to ="/"
            >
             <v-list-item-icon>
               <v-icon>mdi-home</v-icon>
             </v-list-item-icon>
   
             <v-list-item-content>
               <v-list-item-title>Inicio</v-list-item-title>
             </v-list-item-content>
           </v-list-item>
           <v-list-item
              to ="/incidencia"
            >
             <v-list-item-icon>
               <v-icon>mdi-pencil-plus</v-icon>
             </v-list-item-icon>
   
             <v-list-item-content>
               <v-list-item-title>Incidencia</v-list-item-title>
             </v-list-item-content>
           </v-list-item>
         </v-list>
       </v-navigation-drawer>

   
       <v-app-bar
         :clipped-left="true"
         app
         fixed
         color="#F47920"
       >
         <v-app-bar-nav-icon
           v-if="$vuetify.breakpoint.smAndDown"
           @click.stop="primaryDrawer.model = !primaryDrawer.model"
         />
         <v-img
           class="mx-2"
           src="img/logo-2.png"
           max-height="60"
           max-width="60"
           contain
         ></v-img>
         <v-spacer/>
         <div v-if="!$vuetify.breakpoint.smAndDown" >
            <span 
              key="home"
           >
             <v-btn text color="white" to="/"><v-icon>mdi-home</v-icon> Inicio </v-btn>
           </span>
           <span 
              key="incidencia"
           >
             <v-btn text color="white" to="/incidencia"><v-icon>mdi-pencil-plus</v-icon> Incidencia </v-btn>
           </span>
         </div>
         
       </v-app-bar>
    </div>
</template>
<script>
export default {
    name: 'navbar-incidencia',
    data: () => ({
      primaryDrawer:{model:false}
    }),
  };
  </script>