import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from './components/home.vue';
import Incidencia from './components/incidencia.vue';

Vue.use(VueRouter)

const routes = [
  { path: '/', name: 'Home', component: Home},
  { path: '/incidencia', name: 'Incidencia', component: Incidencia}
]

const router = new VueRouter({
    mode: "history",
    routes,
    scrollBehavior() {
        var scroll = 0;
        return window.scrollTo({ top: scroll, behavior: 'smooth' });
    },
    // scrollBehavior: () => ({ y: 0 }),
});

export default router;
