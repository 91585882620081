<template>
  <div id="citysol">
    <a id="home"/>
    <!-- Header Section Start --> 
    <!-- <transition name="fade" mode="out-in" appear>
      <top/>
    </transition> -->

    <!--  search overlay 
    <transition name="fade" mode="out-in" appear>
      <search/>
    </transition>-->

    <!-- Hero Section Start -->
    <transition name="fade" mode="out-in" appear>
      <hero/>
    </transition>

    <!-- About Section Start -->
    <transition name="fade" mode="out-in" appear>
      <a id="about"/>
      <about/>
    </transition>

    <!-- Featured Properites Start  
    <transition name="fade" mode="out-in" appear>
      <featured/>
    </transition>-->  

      
    <!-- Choose Section Start 
    <transition name="fade" mode="out-in" appear>
      <choose/>
    </transition>-->

    <!-- Featured Properites Start -->   
    <transition name="fade" mode="out-in" appear>
      <properties/>
    </transition>
    
    <!-- Ortiz Banner Area Start -->
    <transition name="fade" mode="out-in" appear>
      <banner/>
    </transition>
      
    <!-- Our Agents Section Start     
    <transition name="fade" mode="out-in" appear>
      <agents/>
    </transition>   -->
      
    <!-- Testimonial Section Start 
    <transition name="fade" mode="out-in" appear>
      <testimonial/>
    </transition>      -->
        
    <!-- Latest Blog Section Start 
    <transition name="fade" mode="out-in" appear>
      <latestBlog/>
    </transition>      -->

    <!--- <transition name="fade" mode="out-in" appear>
      <router-link to="/incidencia" tag="button">Escribir incidencia</router-link>
    </transition> -->

    <transition name="fade" mode="out-in" appear>
      <contact/>
    </transition> 
        
    <!-- Footer Section Start -->
    <transition name="fade" mode="out-in" appear>
      <foot/>
    </transition> 
  </div>
    
</template>

<script>
/*eslint-disable*/
import incidencia from './incidencia';
import top from './top';
import search from './search';
import hero from './hero';
import about from './about';
import featured from './featured';
import choose from './choose';
import properties from './properties';
import banner from './banner';
import agents from './agents';
import testimonial from './testimonial';
import latestBlog from './latestBlog';
import contact from './contact';
import foot from './foot';

export default {
  name:"layout",
  components:{
    incidencia,
    top,
    search,
    hero,
    about,
    featured,
    choose,
    properties,
    banner,
    agents,
    testimonial,
    latestBlog,
    contact,
    foot,
  }
}
</script>